import { equals, __ } from "ramda";
import { createContext, useCallback, useContext, useMemo } from "react";

type ContextType =
  | {
      isSignedOn: false;
    }
  | {
      isSignedOn: true;
      userKey: Readonly<string>;
      staffId: Readonly<string>;
      resourceId: Readonly<string> | null;
      firstName: Readonly<string>;
      lastName: Readonly<string>;
      country: Readonly<string>;
      roles: Readonly<Array<"audiologist" | "admin" | "warehouse-worker" | "office-worker" | "no-role">>;
      region: {
        regionKey: Readonly<string>;
        timeZone: Readonly<string>;
      };

      // productsInCart: Readonly<number>;
      // reloadCart: () => void;

      apiTokenSiniInvoice: Readonly<string>;
      apiTokenSiniBook: Readonly<string>;
      apiKeyGooglePlaces: Readonly<string>;
      googleMapId: Readonly<string>;
    };

export const UserInformationContext = createContext<ContextType>({
  isSignedOn: false,
});
UserInformationContext.displayName = "UserInformationContext";

export const useUserInformation = () => {
  const maybeUserInformation = useMaybeUserInformation();

  if (maybeUserInformation.isSignedOn === false) {
    throw new Error(
      "Cannot use hook useUserInformation if user is not signed on"
    );
  }

  return maybeUserInformation;
};

export function useMaybeUserInformation() {
  const userRolesContext = useContext(UserInformationContext);

  const hasRole = useCallback(
    (role: string) =>
      userRolesContext.isSignedOn === true &&
      userRolesContext.roles.some(equals(__, role)),
    [userRolesContext]
  );

  const hasAdminReadAccess = useMemo(
    () => hasRole("admin") || hasRole("office-worker"),
    [hasRole]
  );
  const hasAudiologReadAccess = useMemo(
    () => hasRole("audiologist"),
    [hasRole]
  );
  const hasWarehouseWorkerReadAccess = useMemo(
    () => hasRole("warehouse-worker"),
    [hasRole]
  );
  const hasNoRole = useMemo(
    () => hasRole("no-role"),
    [hasRole]
  );

  if (userRolesContext.isSignedOn) {
    return {
      isSignedOn: true as const,
      userKey: userRolesContext.userKey,
      staffId: userRolesContext.staffId,
      resourceId: userRolesContext.resourceId,
      firstName: userRolesContext.firstName,
      lastName: userRolesContext.lastName,
      country: userRolesContext.country,
      roles: userRolesContext.roles,
      region: userRolesContext.region,
      hasRole,
      hasAdminReadAccess,
      hasAudiologReadAccess,
      hasWarehouseWorkerReadAccess,
      hasNoRole,

      // productsInCart: userRolesContext.productsInCart,
      // reloadCart: userRolesContext.reloadCart,

      apiTokenSiniInvoice: userRolesContext.apiTokenSiniInvoice,
      apiTokenSiniBook: userRolesContext.apiTokenSiniBook,
      apiKeyGooglePlaces: userRolesContext.apiKeyGooglePlaces,
      googleMapId: userRolesContext.googleMapId,
    };
  } else {
    return {
      isSignedOn: false as const,
    };
  }
}
